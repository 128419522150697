import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"

import ImageSteam from "../images/steam.svg"
import ImageToaster from "../images/toaster.svg"
import ImageBread from "../images/bread.svg"
import { media } from "../styles/media"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const StyledImage = styled.div`
  width: 100%;
  height: 550px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .visualArea {
    width: 300px;
    height: 300px;
    border-radius: 500px;
    background: linear-gradient(
      36deg,
      rgba(223, 223, 223, 1) 0%,
      rgba(223, 223, 221, 1) 41%,
      rgba(196, 196, 196, 1) 100%
    );
    overflow: hidden;
    position: relative;
    .imgparts {
      position: absolute;
    }
    #toaster {
      width: 160px;
      bottom: 50px;
      left: 80px;
      animation: toast 10s linear 0s infinite forwards;
    }
    #bread {
      width: 100px;
      bottom: 110px;
      left: 110px;
      animation: bread 10s linear 0s infinite forwards;
    }
    #steam {
      width: 30px;
      bottom: 180px;
      left: 110px;
      animation: steam 10s linear 0s infinite forwards;
    }
  }
  @keyframes bread {
    0% {
      transform: translateY(20px) rotate(0deg);
    }
    10% {
      transform: translateY(20px) rotate(2deg);
    }
    13% {
      transform: translateY(-0px) rotate(-1deg);
    }
    30% {
      transform: translateY(0px) rotate(2deg);
    }
    32% {
      transform: translate(20px, -100px) rotate(30deg);
    }
    34% {
      transform: translate(30px, -150px) rotate(45deg);
    }
    38% {
      transform: translate(150px, -300px) rotate(180deg);
    }
    38% {
      opacity: 1;
    }
    80% {
      transform: translate(150px, -300px) rotate(180deg);
      opacity: 0;
    }
    90% {
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes steam {
    0% {
      opacity: 0;
      transform: translateY(15px);
    }
    5% {
      opacity: 1;
      transform: translateY(3px);
    }
    10% {
      opacity: 0;
      transform: translateY(-15px);
    }
    15% {
      opacity: 0;
      transform: translate(20px, 10px);
    }
    18% {
      opacity: 1;
      transform: translate(20px, 0px);
    }
    22% {
      opacity: 0;
      transform: translate(20px, -10px);
    }
    24% {
      opacity: 0;
      transform: translate(50px, 10px);
    }
    26% {
      opacity: 1;
      transform: translate(50px, 0px);
    }
    30% {
      opacity: 0;
      transform: translate(50px, -10px);
    }
    50% {
      opacity: 0;
      transform: translate(20px, 40px) scale(1, 1);
    }
    55% {
      opacity: 0.8;
      transform: translate(20px, 10px) scale(1, 1);
    }
    60% {
      opacity: 0;
      transform: translate(20px, -100px) scale(1.6, 1.6);
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes toast {
    0% {
      transform: rotate(-1deg);
    }
    3% {
      transform: rotate(2deg);
    }
    5% {
      transform: rotate(-2deg);
    }
    8% {
      transform: rotate(1deg);
    }
    15% {
      transform: rotate(-2deg);
    }
    17% {
      transform: rotate(0deg);
    }
    19% {
      transform: rotate(1deg);
    }
    30% {
      transform: rotate(2deg);
    }
    32% {
      transform: rotate(-15deg);
    }
    45% {
      transform: rotate(-20deg);
    }
    50% {
      transform: rotate(0deg);
    }
    55% {
      transform: rotate(0deg) skewX(10deg);
    }
    60% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(00deg);
    }
  }
`

const StyledText = styled.div`
  padding: 50px 0 100px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  h2 {
    font-size: 1.62rem;
    padding-bottom: 1.62em;
    font-weight: bold;
  }
  p {
    line-height: 1.62em;
    width: 64%;
    text-align: center;
  }
  a {
    margin-top: 30px;
    text-decoration: underline;
    line-height: 1.62em;
    font-size: 1.32em;
  }
  ${() =>
    media.sp(css`
      padding: 50px 5%;
      p {
        width: 100%;
      }
    `)}
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <StyledImage>
      <div className="visualArea">
        <img
          id="steam"
          className="imgparts"
          src={ImageSteam}
          alt="404image-steam"
        />
        <img
          id="bread"
          className="imgparts"
          src={ImageBread}
          alt="404image-bread"
        />
        <img
          id="toaster"
          className="imgparts"
          src={ImageToaster}
          alt="404image-toast"
        />
      </div>
    </StyledImage>
    <StyledText>
      <h2>ページが見つかりません</h2>
      <p>
        お探しのページは存在しないようです。
        <br />
        下にあるリンクからページをご覧ください。
      </p>
      <Link to="/">ホームページに戻る</Link>
    </StyledText>
  </Layout>
)

export default NotFoundPage
